import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import loadable from '@loadable/component';

import * as profileSelectors from '@store/profile/selectors';
import { Profile } from '@models/profile';

import { PageLayout } from '~/ui/layouts/Page';

import { Skeleton } from './Portfolio.page.skeleton';

const PortfolioContent = loadable(() => import('./Portfolio.page'), { fallback: Skeleton });

export const PortfolioPageWrapper = () => {
  const profile: Profile = useSelector(profileSelectors.selectProfile);
  const [title, setTitle] = useState<React.ReactNode>(<>Portfolio</>);
  const hasAlpacaAccount = profile.has_alpaca_account;

  return (
    <PageLayout title={title} showFundBtn={!hasAlpacaAccount}>
      <PortfolioContent setTitle={setTitle} />
    </PageLayout>
  );
};

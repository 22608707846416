import { useSelector } from 'react-redux';
import { selectProfile } from '@store/profile/selectors';

const RISK_LEVELS = [
  {
    label: 'low-risk',
    percents: '5-6',
  },
  {
    label: 'low/medium-risk',
    percents: '5-7',
  },
  {
    label: 'medium-risk',
    percents: '6-8',
  },
  {
    label: 'medium-opportunistic risk',
    percents: '7-9',
  },
  {
    label: 'opportunistic-risk',
    percents: '8-10',
  },
];

const getLabel
    = (riskLevelIndex: number) =>
      `Your ${RISK_LEVELS[riskLevelIndex].label} portfolio is designed to yield an average annual return of ${RISK_LEVELS[riskLevelIndex].percents}%.`;

export const useRiskLevelLabel = () => {
  const profileData = useSelector(selectProfile);

  if (!profileData) {
    return '';
  }

  return getLabel(profileData.risk_value);
};

import React, { useContext } from 'react';
import { ThemeContext } from '~/contexts/app';
import styles from '../../OnboardingAlpaca.module.css';
import { VisaDetailsSlideComponent } from '@pages/onboarding_alpaca/new2/slides/visa_details/Slide';

interface Props {
  onClickNextBtn: () => void;
  onClickPreviousBtn: () => void;
  isNextPreviousButtonDisabled: boolean;
  handleChangeInput: (type, value) => void;
  visaType: any;
  visaExpiration: any;
  isFirstItem: boolean;
  isLastItem: boolean;
  submit: () => void;
  isLoading: boolean;
  error: string;
  slideName: string;
}

export default function VisaVerification (props: Props) {
  const displayName = useContext(ThemeContext).displayName;

  return (
    <div id={props.slideName} className={styles.kycOuterContainer}>
      <h1 className={styles.kycTitle}>
        Visa verification
      </h1>

      <div className={styles.kycText}>
        Thank you for applying for investing with {displayName}. We need additional
        information to verify your identity before approving your account.
      </div>
      <br />
      <br />

      <div className={styles.kycText}>
        Please update your visa details
      </div>

      <div className={styles.kycSubText}>
        To verify your identity, we need to update your visa type and expiration date.
      </div>

      <VisaDetailsSlideComponent
        title=""
        visaType={props.visaType}
        visaExpirationDate={props.visaExpiration}
        onChange={props.handleChangeInput}
        onNext={props.isLastItem ? props.submit : props.onClickNextBtn}
        onPrev={props.isFirstItem ? null : props.onClickPreviousBtn}
      />
    </div>
  );
}

import { OnboardingContext } from '@pages/onboarding_alpaca/new2/onboard';
import { ReactElement } from 'react';

import SlideType from '../types';
import type Context from '../../context';

export interface Handlable {
  onNext: () => void,
  onPrev: () => void,
}

export default abstract class Slide<C extends Context> {
  protected _type: SlideType;

  public abstract isValid (ctx: C): Promise<boolean>;

  public abstract render ({
    ctx,
    onChange,
    onNext,
    onPrev,
    onEditSlide,
    beforeSubmit,
  }: {
    ctx: C,
    onChange: (field: string, val: any) => void,
    onNext: () => void,
    onPrev: () => void,
    onEditSlide: (type: SlideType) => Promise<void>;
    beforeSubmit: (ctx: OnboardingContext) => Promise<void>;
  }): ReactElement;

  public getType (): SlideType {
    return this._type;
  }
}

import { some, values } from 'lodash';

export const requiredText = (val: string) => !val || val?.length === 0
  ? 'Required'
  : '';

export const hasError = (error: string): boolean => error?.length > 0;

export const hasErrors = (errors: object): boolean => (
  some(values(errors), e => e?.length)
);

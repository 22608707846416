import styled from 'styled-components';

import { gaps } from '~/ui/kit/constants/sizes';
import { media } from '~/ui/utils/media';

import { InfoControlFeature } from './features/InfoControl';
import { InfoPopupWidget } from './widgets/InfoPopup';

export const ContentContainer = styled.div`
  ${media.md`
    max-width: 750px;
  `}
`;

export const TitleContainer = styled(ContentContainer)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const InfoFeature = styled(InfoControlFeature)`
  color: var(--eko-primary-color-light-3);
`;

export const InfoPopup = styled(InfoPopupWidget)``;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${gaps.m}px;

  ${media.md`
    grid-template-columns: 2fr 3fr;
  `}
`;

export const StatsContainer = styled(GridContainer)`
  margin-bottom: ${gaps.xxl * 2}px;
`;

import React from 'react';
import { useSelector } from 'react-redux';

import { Profile } from '@models/profile';
import { selectProfile } from '@store/profile/selectors';

import * as Skeletons from './PerformancePage.skeleton.styled';

const PerformancePageSkeleton = () => {
  const profile: Profile = useSelector(selectProfile);

  const is_active_investor = profile.is_active_investor;

  return (
    <>
      <Skeletons.Finances $isActiveInvestor={is_active_investor} />
      {is_active_investor
        ? (
          <Skeletons.InvestorContent>
            <Skeletons.Holdings />
            <Skeletons.Highlighted />
          </Skeletons.InvestorContent>
        )
        : <Skeletons.FinProjection />
      }
    </>
  );
};

export const Skeleton = <PerformancePageSkeleton />;

import React, { FC, useMemo } from 'react';

import { Props as AccordionProps } from '~/ui/kit/organisms/Accordion';

import { useAnnualFee } from '@utils/useAnnualFee';
import * as routes from '@routes/names';

import { useRiskLevelLabel } from '../../hooks/useRiskLevelLabel';
import * as texts from './InfoPopup.texts';
import * as S from './InfoPopup.styled';

type Props = {
  isVisible: boolean;
  className?: string;
  onClose: VoidFunction;
  onOnboardingClick: VoidFunction
};

export const InfoPopupWidget: FC<Props> = (props) => {
  const riskLevelLabel = useRiskLevelLabel();
  const annualFee = useAnnualFee();

  const mainTexts = useMemo(() => texts.getMainTexts(riskLevelLabel), [riskLevelLabel]);
  const items: AccordionProps['items'] = useMemo(() => texts.getDropdownTexts(annualFee).map(
    item => ({
      key: item.key,
      label: item.title,
      children: item.text
    })
  ), [annualFee]);

  return (
    <S.Container
      className={props.className}
      isVisible={props.isVisible}
      title="Your portfolio"
      onClickNo={props.onClose}
      description={(
        <>
          If you have more questions, check out our
          {' '}<S.KnowledgeCenterButton link={routes.FAQ}>Knowledge center</S.KnowledgeCenterButton>
        </>
      )}
    >
      <S.MainTextsContainer>
        {mainTexts.map(text => <li key={text}>{text}</li>)}
        <li>Click <S.ActionButton onClick={props.onOnboardingClick}>here</S.ActionButton> to take the portfolio tour again</li>
      </S.MainTextsContainer>
      <S.SelectableTexts items={items} />
    </S.Container>
  );
};

import * as Styled from './Agreement.styled';

export const NonVendorAgreement = () => (
  <>
    I have read and agree with{' '}
    <span>
      <Styled.AgreementButton href="https://files.alpaca.markets/disclosures/library/AcctAppMarginAndCustAgmt.pdf">
        Alpaca Customer Agreement
      </Styled.AgreementButton>
      ,{' '}
      <Styled.AgreementButton href="https://eko.investments/legal/InvestmentManagementAgreement.pdf">
        Eko Investment Management Agreement
      </Styled.AgreementButton>
      ,{' '}
    </span>
        and sign them electronically
  </>
);

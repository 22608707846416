import { createRef, useState } from 'react';

export const buildPress = ({ onEnter = null, onTab = null }) => (e: KeyboardEvent) => {
  if (e.key === 'Enter' && onEnter !== null) {
    e.preventDefault();
    onEnter();
    return;
  }

  if (e.key === 'Tab' && onTab !== null) {
    e.preventDefault();
    onTab();
    return;
  }
};

export const useReferences = (references: number) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const refs = new Array(references)
    .fill(null)
    .map(() => createRef<HTMLInputElement>());

  const onTab = () => {
    if (currentIndex === refs.length - 1) {
      refs[0].current?.focus();
      setCurrentIndex(0);
    }
    else {
      refs[currentIndex + 1].current?.focus();
      setCurrentIndex(currentIndex + 1);
    }
  };

  const buildFocus = (index: number) => () => {
    setCurrentIndex(index);
  };

  return {
    refs,
    onTab,
    buildFocus,
  };
};

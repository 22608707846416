import React, { MouseEventHandler, useCallback } from 'react';

import * as S from './InfoControl.styled';

type Props = {
  onClick:VoidFunction;
  className?: string;
}

export const InfoControlFeature: React.FC<Props> = (props) => {
  const onClick: MouseEventHandler = useCallback((e) => {
    e.stopPropagation();
    props.onClick();
  }, [props.onClick]);

  return (
    <S.Container className={props.className}>
      <S.ActionButton onClick={onClick}>
        <S.Icon />
      </S.ActionButton>
    </S.Container>
  );
};

import Context from '../../context';

export enum Fields {
  VISA_TYPE = 'visaType',
  VISA_EXPIRATION_DATE = 'visaExpirationDate',
}

export default interface VisaDetailsContext extends Context {
  [Fields.VISA_TYPE]: string;
  [Fields.VISA_EXPIRATION_DATE]: string;
}

export const VisaDetailsDefaults: VisaDetailsContext = {
  [Fields.VISA_TYPE]: '',
  [Fields.VISA_EXPIRATION_DATE]: '',
};

import React from 'react';

import * as Styled from './Agreement.styled';

export const VendorAgreement: React.FC<{ disclosuresUrl?: string | null }> = (props) => (
  <>
        I have read and agree with{' '}
    <span>
      <Styled.AgreementButton href="https://eko.investments/legal/InvestmentManagementAgreement.pdf">
        Eko Investment Management Agreement
      </Styled.AgreementButton>
      ,{' '}
      {props.disclosuresUrl && (
        <>
          <Styled.AgreementButton href={props.disclosuresUrl}>
              Disclosure Statement
          </Styled.AgreementButton>
            , {' '}
        </>
      )}
      <Styled.AgreementButton href="https://eko.investments/legal/TermsOfUse.pdf">
        Terms of Use
      </Styled.AgreementButton>
      {' and '}
      <Styled.AgreementButton href="https://eko.investments/legal/PrivacyPolicy.pdf">
        Privacy Policy
      </Styled.AgreementButton>
      ,{' '}
      <Styled.AgreementButton
        href="https://files.alpaca.markets/disclosures/library/AcctAppMarginAndCustAgmt.pdf">
        Alpaca Customer Agreement
      </Styled.AgreementButton>
      ,{' '}
    </span>
        and sign them electronically
  </>
);

import styled from 'styled-components';
import { Skeleton as UISkeleton } from '~/ui/kit/atoms/Skeleton';

export const ValueSkeleton = styled(UISkeleton.Block)`
    min-height: 68px;
`;

export const BreakdownSkeleton = styled(UISkeleton.Block)`
  min-height: 150px;
`;

export const HoldingsSkeleton = styled(UISkeleton.Block)`
  min-height: 500px;
`;

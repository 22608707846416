import styled from 'styled-components';

export const AgreementButton = styled.a.attrs({
  tabIndex: -1,
  target: '_blank'
})`
  color: var(--eko-primary-color);
  text-decoration: underline;
  background-color: transparent;
  border: none;
  padding: 0;
  
  &:hover {
    color: var(--eko-primary-color) !important;
  }
`;

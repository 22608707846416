import moment from 'moment';

export const validateType = (type: string): string => type.length === 0
  ? 'Required'
  : '';

export const validateExpiration = (date: string): string => {
  if (date.length === 0) {
    return 'Required';
  }
  const inputDate = moment(date);
  const currentDate = moment();
  const currentDatePlus90 = moment().add(90, 'day');

  if (inputDate.isBefore(currentDate)) {
    return 'You need a valid visa to apply for an account';
  }
  else if (
    inputDate.isAfter(currentDate) &&
    inputDate.isBefore(currentDatePlus90)
  ) {
    return 'Visa expiration date must be greater than 90 days from the date of the application';
  }
  else {
    return '';
  }
};

export const validate = (type: string, date: string): boolean => (
  !validateType(type).length
  && !validateExpiration(date).length
);

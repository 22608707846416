import { formatMoney } from '@utils/money';

export const getMainTexts = (riskLevelLabel: string) => [
  'This portfolio is created for you, in line with your risk level, investment horizon, and preferences.',
  'Your portfolio comprises specific equities from the S&P 500 and established bonds.',
  riskLevelLabel,
  'We execute portfolio rebalancing and tax-loss harvesting for you, to ensure giving you the best investment experience and returns.',
];

export const getDropdownTexts = (annualFee: number) => [
  {
    key: 1,
    title: 'Can I make my own investment decisions?',
    text: 'While your personalized portfolio is attuned to your investment aspirations and horizon, you retain the flexibility to introduce or remove holdings as desired. It is important to note, however, that such modifications can influence the risk profile of your portfolio.'
  },
  {
    key: 2,
    title: 'What are the costs of using this product?',
    text: `We charge ${formatMoney(annualFee)}% of your portfolio value per year. This means if your portfolio value is $1,000, you pay $${formatMoney(1000 * annualFee / 100)} per year.`
  },
  {
    key: 3,
    title: 'Is my portfolio under active management?',
    text: 'Yes, as part of our service, your portfolio undergoes automatic rebalancing to align with your risk tolerance. Furthermore, we implement tax-loss harvesting to optimize performance, thereby minimizing tax payments and enhancing your net returns.'
  },
  {
    key: 4,
    title: 'Why does my portfolio consist of individual stocks/bonds and not simply an ETF?',
    text: 'Rather than selecting a single ETF, our approach involves investing in a subset of the S&P 500 that closely replicates its performance. This decision is rooted in the desire to reduce the fees associated with ETFs. Additionally, the incorporation of individual stocks and bonds enhances the efficacy of tax-loss harvesting, leaving you with higher net returns at the end of the year.'
  },
];

import { alpacaOnboarding } from '~/services/alpaca';

export default class OnboardingRequest {
  constructor (context) {
    this._context = context;
  }

  async send () {
    return await alpacaOnboarding(this._getPayload())();
  }

  getCountryOfCitizenShipValueAttributes = (value) => {
    const {
      visaType,
      visaExpirationDate,
      dateOfDepartureFromUSA,
      countryOfCitizenship,
      countryOfBirth,
    } = this._context;

    switch (value) {
      case 'yes':
        return { country_of_citizenship: 'USA' };
      case 'greenCard':
        return {
          permanent_resident: true,
          country_of_citizenship: countryOfCitizenship,
          country_of_birth: countryOfBirth,
        };
      case 'onVisa':
        const getDateOfDeparture = () => {
          if (visaType === 'B1' || visaType === 'B2') {
            return {
              date_of_departure_from_usa: dateOfDepartureFromUSA,
            };
          } else {
            return {};
          }
        };
        return {
          visa_type: visaType,
          visa_expiration_date: visaExpirationDate,
          ...getDateOfDeparture(),
          country_of_citizenship: countryOfCitizenship,
          country_of_birth: countryOfBirth,
        };
      case 'no':
        return '';
    }
  };

  getEmploymentAttributes = (employmentStatus) => {
    if (employmentStatus === 'employed') {
      const { employerName, employerAddress, employerPosition } = this._context;
      return {
        employer_name: employerName || '',
        employer_address: employerAddress || '',
        employment_position: employerPosition || '',
      };
    } else {
      return {};
    }
  };

  getPoliticallyExposedAttributes = (isYouOrFamilyMemberExposed) => {
    if (isYouOrFamilyMemberExposed === 'true') {
      if (this._context.isPoliticallyExposed === 'true') {
        return {
          is_politically_exposed: true,
          immediate_family_exposed: false,
        };
      } else if (this._context.isPoliticallyExposed === 'false') {
        return {
          immediate_family_exposed: true,
          is_politically_exposed: false,
        };
      }
    } else {
      return {
        is_politically_exposed: false,
        immediate_family_exposed: false,
      };
    }
  };

  _getPayload () {
    const {
      legalFirstName,
      legalMiddleName,
      legalLastName,
      dateOfBirth,
      isUSTaxResident,
      isCitizenOfUS,
      ssn,
      streetAddress,
      unitApartment,
      city,
      state,
      postalCode,
      assetValue,
      fundingValue,
      employmentStatus,
      isAffiliatedWithSEC_FINRA,
      isControlPerson,
      isYouOrFamilyMemberExposed,
      ipAddress,
      currentTimeStamp,
      phoneNumber,
      stamps,
      email,
      isITIN,
    } = this._context;

    let liquid_net_worth_min;
    let liquid_net_worth_max;

    if (assetValue && assetValue === '< $20,000') {
      liquid_net_worth_max = assetValue
        .split('< ')[1]
        .split('$')[1]
        .split(',')
        .join('');
      liquid_net_worth_min = 0;
    } else if (assetValue && assetValue === '> $500,000') {
      liquid_net_worth_min = assetValue
        .split('> ')[1]
        .split('$')[1]
        .split(',')
        .join('');
      liquid_net_worth_max = 9999999;
    } else {
      const minMaxArray = assetValue.split(' - ');
      liquid_net_worth_min = minMaxArray[0].split('$')[1].split(',').join('');
      liquid_net_worth_max = minMaxArray[1].split('$')[1].split(',').join('');
    }

    return {
      identity: {
        given_name: legalFirstName,
        middle_name: legalMiddleName,
        family_name: legalLastName,
        date_of_birth: dateOfBirth,
        country_of_tax_residence: isUSTaxResident === 'true' ? 'USA' : null,
        ...this.getCountryOfCitizenShipValueAttributes(isCitizenOfUS),
        tax_id_type: isITIN ? 'USA_TIN' : 'USA_SSN',
        tax_id: ssn,

        liquid_net_worth_min: Number(liquid_net_worth_min),
        liquid_net_worth_max: Number(liquid_net_worth_max),
        funding_source: [fundingValue],
      },
      contact: {
        street_address: streetAddress,
        unit: unitApartment,
        city: city,
        state: state,
        postal_code: postalCode,
        email_address: email,
        country: 'USA',
        phone_number: phoneNumber,
      },
      disclosures: {
        employment_status: employmentStatus,
        ...this.getEmploymentAttributes(employmentStatus),
        is_control_person: isControlPerson === 'true',
        is_affiliated_exchange_or_finra: isAffiliatedWithSEC_FINRA === 'true',
        ...this.getPoliticallyExposedAttributes(isYouOrFamilyMemberExposed),
      },
      agreements: [
        {
          agreement: 'margin_agreement',
          signed_at: stamps.margin_agreement,
          ip_address: ipAddress,
        },
        {
          agreement: 'account_agreement',
          signed_at: stamps.account_agreement,
          ip_address: ipAddress,
        },
        {
          agreement: 'customer_agreement',
          signed_at: stamps.customer_agreement,
          ip_address: ipAddress,
        },
      ],
    };
  }
}

import { GenericError } from '@pages/onboarding_alpaca/new2/slides/common/Error';
import { buildPress, useReferences } from '@pages/onboarding_alpaca/new2/slides/utils';
import { hasErrors } from '@pages/onboarding_alpaca/new2/slides/validators';
import { FormEvent, forwardRef, useImperativeHandle, useState } from 'react';
import moment from 'moment';
import { some, values } from 'lodash';

import s from '../form.module.css';

import { TextInput } from '@pages/onboarding_alpaca/OnboardingAlpacaFields';
import SelectDropdown from '@components/SelectInput/SelectInput';

import VisaDetailsContext, { Fields } from '../visa_details/context';
import { visaTypes } from '@utils/alpacaUtils';

import { Validable } from '../base/validators';
import { validateExpiration, validateType } from '../visa_details/validators';

interface Props {
  visaType: string;
  visaExpiration: string;
  onChange: (field: keyof VisaDetailsContext, val: VisaDetailsContext[keyof VisaDetailsContext]) => void;
  onComplete: () => void;
}

const Form = forwardRef(({
  visaType,
  visaExpiration,
  onChange,
  onComplete,
}: Props, ref) => {
  const refs = useReferences(2);

  const [errors, setErrors] = useState<VisaDetailsContext>({
    [Fields.VISA_TYPE]: '',
    [Fields.VISA_EXPIRATION_DATE]: '',
  });

  useImperativeHandle(ref, (): Validable => ({
    async validate (): Promise<boolean> {
      const errs: VisaDetailsContext = {
        [Fields.VISA_TYPE]: validateType(visaType),
        [Fields.VISA_EXPIRATION_DATE]: validateExpiration(visaExpiration),
      };
      setErrors(errs);
      return !some(values(errs), e => e.length);
    },
  }));

  return (
    <div className={s.form}>
      <div>
        <SelectDropdown
          style={{ backend: 'white', color: 'black' }}
          value={visaType}
          onChange={(e: FormEvent<HTMLSelectElement>) => onChange(
            Fields.VISA_TYPE,
            e.currentTarget.value,
          )}
          options={visaTypes}
          refvalue={refs.refs[0]}
          onKeyDown={buildPress({
            onEnter: onComplete,
            onTab: refs.onTab,
          })}
          onFocus={refs.buildFocus(0)}
        />

        <div className={s.form__field__help}>
          For sub visa, choose its parent category
        </div>

        {!!errors[Fields.VISA_TYPE].length && (
          <div className={s.form__field__error}>
            {errors[Fields.VISA_TYPE]}
          </div>
        )}
      </div>

      <div>
        <div className={s.form__field__title}>
          Visa expiration date:
        </div>

        <TextInput
          type="date"
          min={moment().add(91, 'day').format('YYYY-MM-DD')}
          style={{
            textTransform: 'unset',
            background: 'white',
            color: 'black',
          }}
          value={visaExpiration}
          onChange={(v: FormEvent<HTMLInputElement>) => onChange(
            Fields.VISA_EXPIRATION_DATE,
            v.currentTarget.value,
          )}
          isInvalid={!!errors[Fields.VISA_EXPIRATION_DATE].length}
          refvalue={refs.refs[1]}
          onKeyDown={buildPress({
            onEnter: onComplete,
            onTab: refs.onTab,
          })}
          onFocus={refs.buildFocus(1)}
        />

        {!!errors[Fields.VISA_EXPIRATION_DATE].length && (
          <div className={s.form__field__error}>
            {errors[Fields.VISA_EXPIRATION_DATE]}
          </div>
        )}
      </div>

      {hasErrors(errors) && (
        <GenericError />
      )}
    </div>
  );
});

export default Form;
